// Logo

class Header {
    constructor() {}
    addEventListeners() {
        this.cta.addEventListener('click', this.openCalendly.bind(this))
        this.cta2.addEventListener('click', this.openSelar.bind(this))
    }

    openCalendly() {
        window.open('http://www.calendly.com/zanesolicitors', '_blank').focus()
    }

    openSelar() {
        window.open('https://selar.co/p31v2d', '_blank').focus()
    }

    render() {
        const div = document.createElement('div')
        div.classList = 'align-center flex'
        div.id = 'showcase'
        div.innerHTML = `
            <div class="container">
                <h1 class="lg-heading mb-2">Modern legal solutions for Nigerian Businesses.</h1>

                <button class="btn btn-outline-light mr-1" id='cta'>Book free intro call</button>
                <div class="break hide mb-1"></div>
                <button class="btn btn-light" id='cta2'>Book consultation</button>
                
            </div>
        `

        document.querySelector('header').appendChild(div)
        this.cta = document.querySelector('#cta')
        this.cta2 = document.querySelector('#cta2')

        this.addEventListeners()
    }
}

export default new Header()
